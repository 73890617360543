.contact-page{
    width: 70%;

    .intro-info{
        position: relative;
        margin: 50px 0;

        .intro{
            height: 150px;
        
            h1{
            }
        }

        .intro-sidebar{
            width: 40%;
            height: 250px;
        }

        .email-sidebar{
            width: 40%;
        }

        .social-sidebar{
            position: absolute;
            width: 40%;
            top: 150px;
            right: 5%;
            padding: 30px;
            border-radius: 25px;
            border: 2px solid #dee3e6;

            .social-links{

                padding: 0;

                li{

                    svg{
                        display: inline-block;
                        vertical-align: middle;
                    }

                    h3{
                        margin: 20px 5px;
                        font-size: 16px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .faq{
        margin: 40px 0;

        ul{

            padding: 0;

            li{
                padding: 0;
            }
        }
    }
}