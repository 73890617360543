.about-page{

    .about-header{
        margin-top: 100px;
        margin-right: auto;
        margin-left: auto;
        width: 60%;

        .banner{
            display: inline-block;
            width: 45%;
            margin-left: 5%;

            vertical-align: top;


            img{
                margin: 15px auto;
                display: block;
            }
        }

        .summary{
            margin: 0 auto;
            display: inline-block;
            width: 50%;
            vertical-align: top;
            
            .title{
            }

            .team-image{

            }
        }
    }

    .team{
        width: 85%;
        margin: 100px auto;

        .team-info{
            display: inline-block;
            width: 50%;
        }

        .image-wrapper{
            display: inline-block;
            width: 45%;
            margin-left: 5%;

            .team-image{
                width: 100%;
            }
        }
    }


    .founders{
        width: 85%;
        margin: 100px auto;
        margin-bottom: 50px;

        .founder-john{

        }

        .founder-ridong{

            .founder-info{
                text-align: right;
            }
        }

        .founder{
            vertical-align: top;
            position: relative;
            margin: 20px auto;

            .founder-info{
                display: inline-block;
                width: 50%;
                vertical-align: top;
                margin: 0 20px;

                .founder-description{

                }
    
                .founder-name{
                    margin: 0;
                }
            }

            .portrait-wrapper{
                display: inline-block;
                vertical-align: top;
                width: 40%;

                .portrait{
                    display: block;
                    margin: 0 auto;
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
}