.works-page{ 
    width: 70%;
    
    .past-works{

        .work{
            margin: 120px auto;

            .game-image-wrapper{
                display: inline-block;
                vertical-align: top;
                width: 50%;

                .banner{
                    width: 100%;
                }
                
            }

            .game-info{
                display: inline-block;
                margin-left: 5%;
                vertical-align: top;
                width: 40%;
                height: 100%;

                h3{
                    margin-top: 0;
                }
            }

            .game-site-link{
            }
        }
    }
}