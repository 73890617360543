@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

.under-construction-component{
  width: 100vw;
  height: 100vh;
  background: rgb(191,187,159);
  background: linear-gradient(90deg, rgba(191,187,159,1) 0%, rgba(225,156,228,1) 33%, rgba(178,255,245,1) 100%);
  text-align: center;

  .under-construction-title{
    color: white;
    position: relative; 
    top: 40%;

    .title{ 
      font-family: 'Roboto Condensed', sans-serif;
      color: white;
      font-size: 72px;
      margin: 0;
      font-weight: 700;
    }

    .timer{
      margin: 10px 0px;
      display: inline-flex;

      .timer-container{
        width: 50px;
        padding: 0 10px;
      }
    }

    .timer-value{
      font-family: 'Roboto Condensed', sans-serif;
      padding: 20px 0;
      font-size: 30px;  
      color: white;
    }

    .timer-text{
      
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 12px;
      color: rgb(58, 55, 55);
    }

    .social-links{
      padding: 0;
      margin: 20px 0;
    }

    .social-links li{
      display: inline-block;
      padding: 10px;
      
      svg {
        display: block;
        margin: 0 auto;
        color: white;
      }
    }
  }
}
