.press-page{
    width: 70%;

    .MuiDivider-root{
        margin: 20px 0;
    }

    .section-title{
        margin-top: 5px;
        margin-bottom: 28px;
    }

    .banner{
        background-image: url('../../../../assets/imgs/header.png');
        height: 200px;
        background-size: cover;
        background-position: center; /* Center the image */
        width: 100%;
    }

    .press-video{
        position:relative;
        position: relative;
        padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */ 

        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .fact-sheet{
        vertical-align: top;

        .facts{
            display: inline-block;
            vertical-align: top;
            

            ul{
            
                padding: 0;
            }
        }

        .game-description{
            position: relative;
            display: inline-block;
            vertical-align: top;
            left: 300px;
            
            p{
                width: 500px;
            }
        }
    }

    .features{

        ul{
            padding: 0;
        }
    }

    .screenshots{


    }

    .assets{
        
    }

    .image-grid{
        width: 100%;

        .gallery-wrapper{
            display: inline-block;
            width: 45%;
            margin: 20px 0;
            padding-right: 5%;

            button{
                padding: 0;
                width: 100%;

                img{
                    width: inherit;
                }
            }
        }
    }

    .studio-information{

        .credits{
            display: inline-block;

            ul{
                padding: 0;
            }
            
        }

        .contact-information{
            position: relative;
            display: inline-block;
            left: 300px;

            ul{
                padding: 0;
            }
        }
    }

    li{

        h4{
            margin: 10px 0;
        }

        p{
            margin: 10px 0;
        }
        margin: 5px 0;
    }
}