@import '../../App/App.scss';

.footer{
    margin: 20px auto 90px auto;
    width: 80%;
    
    .footer-social{
        display: flex;
        justify-content: center;
        margin: 20px auto;
        text-align: center;

        .social-tag{
            display: inline-flex;
            margin: 0 20px;
            align-items: center;;
        }

        .social-links{
            display: inline-flex;
            justify-content: center;
            margin: auto 20px;
            padding: 0;

            li{
                margin: 5px 10px;
                a{

                }
            }

            .twitter-link{

            }

            .discord-link{
                
            }

            .instagram-link{

            }

            .youtube-link{

            }

            .circle{
                width: 30px;
                height: 30px;
                border: 2px solid #1c1b19;
                border-radius: 50%;
                text-align: center;
                padding: 12px;

                transition:all 0.2s ease-in;

                &:hover{
                    fill: map-get($secondary-colours, "error");
                }
            }
        }
    }

    .footer-ratings{
        display: flex;
        margin: 0 auto;
        justify-content: center;

        img{
            margin:0 10px;
        }

        .esrb-logo{
            display: inline;
            vertical-align: middle;
            max-width:230px;
            max-height:95px;
            width: auto;
            height: auto;
        }

        .steam-logo{
            display: inline;
            vertical-align: middle;
            max-width:230px;
            max-height:95px;
            width: auto;
            height: auto;
        }
    }

    .footer-disclaimer{
        margin: 50px auto;
        text-align: center;
        width: 900px;
        color: #b9b7ad;
    }

    .footer-legal{
        margin: 30px auto;
        left: 0;
        text-align: center;
        //color: map-get($grey-colours, 'med-grey1');
        color: #b9b7ad;

        li{
            display: inline-block;
            padding-right: 100px;
            text-align: center;
            //color: map-get($grey-colours, 'med-grey1');
            color: #b9b7ad;

            a{
                color: #b9b7ad;
                transition:color 0.2s ease-in; 
            }

            a:hover{
                color: map-get($secondary-colours, "error");
            }
        }
    }
}