@import '../../App/App.scss';

.header-wrapper{
    height: 100%;
    position: relative;
    height: 140px;
}

.nav-header{
    box-shadow: 0 0 3px rgba(0,0,0,.2);
    position: fixed;
    z-index: 2;
    background: white;
    width: 100%;
    margin: 0;

    .logo{
        vertical-align: middle;;
        position: relative;
        left: 5%;
        background: url("../../assets/imgs/ctrlfyp-logo.png");
        width: 104px;
        height: 86px;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 10px;;
      }

    .nav-links{

        vertical-align: middle;;
        display: inline-block;
        position: relative;
        left: 35%;
        padding-top: 50px;

        li{
            display: inline;

            a{
                font-size: 20px;;
                margin-left: 5vw;
                font-weight: 700;
                transition:color 0.2s ease-in; 
            }

            a:hover{
                color: map-get($secondary-colours, "error");
            }

            .active{

            }
        }
    }
}