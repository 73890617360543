//@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@font-face{
  font-family: "Lato";
  src: url('../assets/fonts/Lato/Lato-Regular.ttf');
  src: local('../assets/fonts/Lato/Lato-Regular.ttf');
}

$secondary-colours: (
  "error":#D10000,
  "warning":#FADD00,
  "success":#00CE00,
);

$primary-colours: (
  "deep-navy":#273C6E,
  "standard-blue":#0E75BB,
  "bright-blue":#26A7DF,
  "beach-sky":#9BE1FF,
);

$grey-colours: (
  "white":#FFFFFF,
  "light-grey1":#EEEEEE,
  "light-grey2":#DDDDDD,
  "med-grey1":#b9b7ad,
  "med-grey2":#888888,
  "dark-grey1":#777777,
  "dark-grey2":#454545,
  "black":#000000,
);

html {
}

body{
  //background-color: #F5F8FA;
}

div, button, input, a, h1, h2, h3, h4, h5, h6, p, input {
  font-family: 'Lato', sans-serif;
  text-decoration: none;
  color: black;
  font-weight: 400;
  font-size: 18px;
};

h4{
  font-size: 14px;
}

input:focus,button:focus,.textarea:focus, input.form-control:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #0E75BB;
  box-shadow: 0px 3px 20px 0px rgba(14,118,187,0.2)!important;
}

label{
  color: #4E4E4E;
}

textarea {
  resize: none;
}

.navbar-brand {
  float: left;
}

h1{
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 36px;
}

h2{
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 30px;
}

h3{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 24px;
}

h4{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 18px;
}

h5{
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 15px;
}

h6{
  font-family: 'Lato', sans-serif;
  font-weight: 200;
  font-size: 12px;
}

p{
  font-size: 14px;
  line-height: 24px;
}

button{
  box-shadow: none;
  border: none!important;
}

ul{
  list-style: none;
  margin-top: 0;
}

li{

}

.section{
  margin: 40px auto;

  .title{
    font-size: 4em;
  }

  .subtitle{
    font-size: 2.5em;
  }

  .caption{
    font-size: 1em;
    margin: 15px 0;
  }
}

.overlay{
  position: absolute; /* Sit on top of the page content */
  //width: 100%; /* Full width (cover the whole page) */
  //height: 100%; /* Full height (cover the whole page) */
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,1); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  display: inline;
  top: 100%;
}

.overlay-enter{
  display: none;
  top: 100%;
}

.overlay-enter-active{
  display: inline;
  top: 0;
  transition: top 800ms;
}

.overlay-enter-done{
  top: 100%;
  display:none;
}

.overlay-exit{
  display: inline;
  top: 100%;
}

.overlay-exit-active{
  display: inline;
  top: 0;
  transition: top 800ms;
}
