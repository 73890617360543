.blog-section{
    margin-left: 15%;

    .post-listings{
        padding: 0;

        .post-listing{
            margin: 20px 0;
            padding: 20px 0;

            .post-image-wrapper{
                display: inline-block;
                margin-right: 30px;
                box-shadow: 0 8px 17px rgba(0,0,0,.15);
                height: 100%;

                img{
                    position: relative;
                    height: 125px;
                    background-position: 50%;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }

            .post-info{
                display: inline-block;
                margin-left: 10px;

                .post-tag{
                    margin: 3px 0;
                }

                .post-title{
                    margin-bottom: 3px;
                    font-size: 1.4em;
                    margin-top: 0;
                }
        
                .post-author{
                    display: inline-block;
                    margin: 0;
                }
        
                .post-date{
                    margin: 0 30px;
                    display: inline-block;
                }
            }
        }
    }
}