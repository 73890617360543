@import '../../../../App/App.scss';

.job-description{
    width: 100%;

    .job-display{
        display: inline-block;
        position: relative;
        width: 25%;
        margin-right: 5%;

        .job-photo{
            background-image: url('../../../../assets/imgs/test-image-001.png');
        }
    }

    .video{
        width: 100%;
    }

    .job-info{
        display: inline-block;
        position: relative;
        margin-left: 5%;
        width: 65%;

        .job-title{
            font-size: 42px;
        }

        .job-requirements{


            .responsibilities{

            }

            .requirement-experience{

            }

            
            .job-listings{

                h3{
                    color: map-get($secondary-colours, "error");
                }

                ul{
                    margin-bottom: 24px;

                    li{
                        font-size: 16px;
                        list-style: disc;
                        padding-left: 15px;
                        margin-left: 12px;
                        margin-bottom: 12px;
                    }
                }
            }
        }

        .action-row{
            
            a{
                width: 70px;
                margin-right: 20px;

                button{
                    width: 70px;
                }
            }
        }
    }
}
