@import '../../../../App/App.scss';

.careers-page{    
    width: 70%;

    .heading{
        margin-bottom: 50px;
    }

    .work-culture{

    }

    .job-table{
        width: 100%;
        margin: 0 auto;

        .table-control{
            vertical-align: middle;

            .job-table-title{
                display: inline;
                vertical-align: middle;
            }

            .job-table-selectors{
                display: inline;
                vertical-align: middle;
                float: right;
                
                .department-dropdown{
                    min-width: 140px;
                    margin: 0 20px;
                }

                .employment-status-dropdown{
                    min-width: 140px;
                    margin: 0 20px;
                }
            }
        }

        .job-link{
            //color: #b9b7ad;
            transition:color 0.2s ease-in; 

            &:hover{
                color: map-get($secondary-colours, "error");
            }
        }
    }
}