.post-page{
    width: 50%;

    .post-image{
        width: 100%;

        img{
            width: 100%;
        }
    }

    .tags-row{

        .tags-title{
            display: inline-block;
        }

        .tags-list{
            display: inline-block;
            padding: 0;

            .tag{
                padding: 0 10px;
            }
        }
    }
}