.error-404{

    .error-picture{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
}

